.menu {
  .company_signature {
    .signature {
      width: 77px;
    }
  }
  .menu_section {
    .navigation_part {
      padding: 20px;
      &.references {
        text-align: center;
      }
      &:nth-of-type(1)::after {
        top: 80%;
        left: 5%;
      }
      &:nth-of-type(3)::after {
        top: 80%;
        width: 140px;
      }
    }
  }
}
