.contact_section {
  height: 100vh;
  .name_section {
    padding-top: 140px;
    text-align: center;

    h2 {
      text-transform: uppercase;
      font-size: 1.2rem;
      position: relative;
      text-align: center;
      padding-bottom: 30px;
      font-weight: 600;
      &::before {
        content: "";
        width: 90px;
        height: 3px;
        background-color: #fcb80b;
        position: absolute;
        bottom: 24px;
        left: 47%;
      }
    }
    div {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      p {
        font-weight: 600;
        padding: 0 20px;
      }
    }
  }
}
.contact {
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .contact_form {
    padding: 25px 50px;
    width: 40%;

    .name_inputs {
      display: flex;
      input {
        width: 100%;
        padding: 15px 0;
        font-family: "Montserrat", sans-serif;
        color: #777;
        margin: 20px 0;
        outline: none;
        text-shadow: none;
        border: 1px solid rgb(116, 110, 110);
        &::placeholder {
          padding: 15px;
        }
      }
      input[type="text"] {
        margin-right: 20px;
      }
    }
    textarea {
      box-sizing: border-box;
      height: 150px;
      margin: 0;
      padding: 0;
      width: 100%;
      font-family: "Montserrat", sans-serif;
      color: #777;
      resize: none;
      outline: none;
      text-shadow: none;
      border: 1px solid rgb(116, 110, 110);
      &::placeholder {
        padding: 15px;
      }
    }
    input[type="submit"]{
      margin-top: 15px;
      width: 30%;
      padding: 15px 0;
      font-family: "Montserrat", sans-serif;
      color: white;
      text-transform: uppercase;
      font-size: 1.2;
      font-weight: 600;
      text-align: center;
      margin: 20px 0;
      outline: none;
      text-shadow: none;
      border: none;
           background-color: map-get($colors, main-yellow );

    }
  }
  .phone-and-mail {
    width: 30%;
    display: flex;
    padding: 20px 50px;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid map-get($colors, main-yellow);

    h2 {
      font-size: 1.1rem;
      font-weight: 600;
      padding: 10px 0;
    }
    i {
      color: map-get($colors, main-yellow);
      padding: 10px;
    }

    p {
      padding: 5px 0 5px 40px;
    }
  }
}

.blabla {
  width: 70%;
}
