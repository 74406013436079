
.banner_background{
    background-image: url("../assets/banner.png");
    // background-image: url("../assets/blueprint-tools.jpg");
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position-y: 20%;
    filter: brightness(0.7) opacity(0.8);
 
    z-index: 1;
    
  }
  .banner_content_area{
       position: absolute;
    top: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .banner_text{
      font-size: 2.5rem;
      text-transform: uppercase;
      color: map-get($colors, text-light );
      font-weight: 600;
      padding: 80px 0;
    }

    .banner_btn_area{
      display: flex;
      
    .banner_btn{
    border-radius: 4px;
    color: white;
    background-color: map-get($colors, main-yellow );
    letter-spacing: 0.7px;
    font-size: 1.6em;
    font-weight: 400;
    transition: 1s;
    margin: 0 10px;
    padding: 25px 50px;
    text-decoration: none;

  &:hover{
    background-color: rgb(179, 181, 182);
    
    transform: translateY(-5px);
  }}
    }
   }
